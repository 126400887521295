#column-founders-1 {
  flex-basis: 100%;
  @include media-breakpoint-up(lg) {
    flex-basis: 0;
  }
}

#column-founders-2 {
  flex-basis: 100%;
  @include media-breakpoint-up(lg) {
    flex-basis: 340px;
    max-width: 340px;
  }
  @include media-breakpoint-up(xl) {
    flex-basis: 398px;
    max-width: 398px;
  }
}

.box-founders-invite-header {
  .title {
    margin: 0;
    font-size: 18px;
    color: $text-color-dark;
    font-weight: $font-weight-bold;
    line-height: 40px;
  }
  .btn {
    width: 100%;
  }
  .col {
    flex-basis: 100%;
  }
  @include media-breakpoint-up(lg) {
    .column-input {
      flex-basis: 0;
    }
    .column-title {
      flex-basis: 139px;
      max-width: 139px;
    }
    .column-btn {
      flex-basis: 127px;
      max-width: 127px;
    }
  }
}
.box-founders-actions {
  background-color: $main-element-header-bg;
  margin: -16px -16px 16px -16px;
  padding: 16px;
  border-bottom: 1px solid $box-border-color;
  .text-description {
    font-weight: $font-weight-normal;
    font-size: 12px;
    line-height: 18px;
  }
  .col {
    flex-basis: 100%;
  }
  .column-actions {
    .btn {
      padding: 0;
      &:first-child {
        width: 165px;
      }
      &:last-child {
        width: 128px;
      }
      & + .btn {
        margin-left: 16px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .column-description {
      flex-basis: 0;
    }
    .column-actions {
      flex-basis: 325px;
      max-width: 325px;
    }
  }
}
.list-category-founders {
  margin-top: 16px;
}
.category-item-founders {
  height: 100%;
  background-color: $box-funding-footer-bg;
  border: 1px solid $box-funding-footer-border;
  padding: 8px 16px 12px 16px;
  border-radius: 4px;
  .category-title {
    font-weight: 900;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: $violet;
    border-bottom: 0.5px solid $violet;
    margin-bottom: 16px;
  }
  &.with-line {
    border: 2px solid $violet;
  }
}
.status-founders {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: $white;
  &.bg-no-data {
    background-color: $white;
    border: 1px solid $in-progress;
  }
}
.list-type-founders {
  &.list-m-8 {
    .type-item-founders {
      margin-bottom: 8px;
    }
  }
  &.list-m-4 {
    .type-item-founders {
      margin-bottom: 4px;
    }
  }
}
.type-item-founders {
  display: flex;
  align-items: center;
  .type-item-founders-title {
    font-size: 14px;
    line-height: 16px;
    color: $text-color-dark;
  }
  .status-founders {
    margin-left: auto;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.summary-item-founders {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  .summary-item-founders-title {
    font-size: 14px;
    line-height: 16px;
    color: $text-color-dark;
    width: 50px;
  }
  .summary-item-founders-percent {
    margin-left: auto;
    width: calc(100% - 60px);
    height: 16px;
  }
}
.most-optimist-item {
  background-color: $box-funding-footer-bg;
  border: 1px solid $box-funding-footer-border;
  padding: 12px 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
  .most-optimist-item-title {
    font-style: normal;
    font-weight: $font-weight-bold;
    font-size: 18px;
    line-height: 32px;
    color: $violet;
    width: 92px;
    min-width: 92px;
    border-right: 1px solid $box-border-color;
  }
  .most-optimist-item-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: 16px;
    .type-item {
      font-size: 14px;
      line-height: 16px;
      padding: 8px 0;
      color: $text-color-dark;
      white-space: pre;
      &:after {
        content: " - ";
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
}

.most-optimist-item-empty {
  background-color: $box-funding-footer-bg;
  border: 1px solid $box-funding-footer-border;
  padding: 0px 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  line-height: 58px;
  justify-content: center;
}


