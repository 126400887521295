.carousel-upload{
    input {
        visibility: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .carousel-upload-empty{
        height: 250px;
        width: 250px;
        border-radius: 4px;
        border: 1px solid $gray-200;
        justify-content: center;
        align-items: center;
        display: flex;
        box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
        transition: all 0.3s ease;
        span.icon{
            transition: all 0.3s ease;
            font-size: 50px;
            color: $gray-200;
            
        }
        &:hover,&.on-dragging{
            background-color: $primary;
            cursor: pointer;
            span{
                color: $white;
            }
        }
    }
}