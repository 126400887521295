.box-master-session-content {
  height: 100%;
  padding: 20px 14px;
  .master-session-title-content {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .master-session-title {
      font-weight: $font-weight-bold;
      font-size: 16px;
      line-height: 24px;
      color: $text-color-dark;
      padding-right: 10px;
      &.center-file{
          margin-top: 18px;
      }
    }
    .master-session-badge-content {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      .master-session-badge + .master-session-badge {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }
  .master-session-description {
    white-space: break-spaces;
    margin: 0;
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 20px;
  }
}
.box-master-session-content-empty {
  height: 100%;
  padding: 20px 14px;
  .title-empty {
    display: block;
    text-align: center;
    color: $text-color-light;
  }
}

.master-session-badge {
  @extend .btn;
  @extend .btn-sm;
  @extend .btn-outline-violet;
  font-weight: $font-weight-medium;
  max-width: 90%;
  pointer-events: none;
}

.master-session-item {
  padding: 16px 22px;
  border-bottom: 1px solid $box-funding-footer-border;
  &:last-child {
    border: none;
  }
  .master-session-file {
    position: relative;
    padding-left: 40px;
    .icon-file {
      position: absolute;
      left: 0;
    }
    .file-name {
      display: block;
      color: $text-color-dark;
      font-size: 14px;
      line-height: 32px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
