#navbar-admin {
    min-height: 70px;
    background-color: $white;
    .navbar-brand {
        img {
            max-width: 151px;
        }
    }

    .navbar-nav {
        .dropdown-item.active, 
        .dropdown-item:active {
            
            background-color: $secondary;
        }
        .nav-link {
            font-family: $font-family-commissioner;
            font-weight: $font-weight-commissioner-normal;
            position: relative;
            color: rgba($white, 0.5);
            font-size: 13px;
            transition: all 0.3s ease;
         
            &:hover {
                color: $white;
            }
            &.active {
                font-weight: $font-weight-commissioner-bold;
                color: $white;
            }
            @include media-breakpoint-up(lg) {
                 margin: 0 10px;
                &.active {
                    &:before {
                        content: "";
                        height: 6px;
                        width: 100%;
                        position: absolute;
                        bottom: -17px;
                        left: 0;
                        background-color: $green;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .navbar-toggler {
            margin-left: auto;
        }
        .navbar-brand {
            img {
                max-width: 100px;
            }
        }
    }
}

// Dashboard
#navbar-dashboard {
    background-color: $white;
    padding: 0;
    border-bottom: 1px solid $navbar-brand-border;
    .navbar-brand {
        margin: 0;
        border-right: 1px solid $navbar-brand-border;
        width: 200px;
        height: 72px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 134px;
        }
    }
    .navbar-toggler {
        margin-right: 15px;
    }
    .navbar-collapse {
        border-bottom: none;
        @include media-breakpoint-up(lg) {
            height: 72px;
        }
    }
    .navbar-nav {
        .nav-item {
            margin: 16px 24px;
            .nav-link {
                padding: 8px;
                font-family: $font-family-commissioner;
                font-size: 14px;
                border-radius: 4px;
                line-height: 24px;
                .icon-link {
                    margin-right: 15px;
                }
                &:hover {
                    color: $active-link;
                    .icon-link {
                        svg {
                            path{
                                fill: $active-link;
                            }
                        }
                    }
                }
                &.active {
                    font-weight: $font-weight-commissioner-bold;
                    color: $active-link;
                    background-color: $box-header-background;
                }
            }
            
        }
        &#logout-navbar-nav{
            @include media-breakpoint-up(lg) {
                margin-right: 8px;
            }
        }
        @include media-breakpoint-down(lg) {
            margin-left: 8px;
            .nav-item {
                margin: 16px 16px;
            }
        }
        @include media-breakpoint-down(md) {            
            .nav-item {
                margin: 8px 12px;
            }
        }
       
    }
    @include media-breakpoint-down(md) {
        border-bottom: 1px solid $navbar-brand-border;

    }
}

// Questionnaire
#main-header-questionnaire{
    padding: 33px;
    .logo-container{
        display: flex;
        width: 100%;
        justify-content: center;
        align-content: center;
    }
}