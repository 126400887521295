.btn {
    min-width: 110px;
    &.btn-loading {
        position: relative;
        overflow: hidden;
        &:after {
            display: inline-block;
            animation: dotty steps(1, end) 1s infinite;
            position: absolute;
            content: "";
        }
    }
    .icon-right {
        margin-left: 10px;
    }
    .icon-left {
        margin-right: 10px;
    }
}
.btn + .btn {
    margin-left: 16px;
}
.btn-md {
    line-height: 30px;
    min-width: 95px;
    height: 32px;
    font-size: 14px;
    font-weight: $font-weight-normal;
    .icon {
        transition: all 0.3s ease;
        margin-right: 5.5px;
        display: inline-block;
        &.open-icon {
            svg {
                vertical-align: sub;
            }
        }
        &.lock-icon {
            svg {
                vertical-align: sub;
            }
        }
    }
}
.btn-sm {
    line-height: 22px;
    min-width: 95px;
    height: 24px;
    font-size: 12px;
    font-weight: $font-weight-normal;
    .icon {
        transition: all 0.3s ease;
        margin-right: 5.5px;
        display: inline-block;
        &.open-icon {
            svg {
                vertical-align: sub;
            }
        }
        &.lock-icon {
            svg {
                vertical-align: sub;
            }
        }
    }
}
.btn-sm + .btn-sm {
    margin-left: 8px;
}

.btn-rounded-action {
    z-index: 4;
    width: 37px;
    height: 37px;
    min-width: 37px;
    // border: solid 1px rgba($dark,0.2);
    border-radius: 37px;
    padding: 0;
    text-align: center;
    line-height: 36px;
    span {
        text-align: center;
        line-height: 36px;
    }
}
.dropdown-btn-action {
    display: inline-block;
    .btn-rounded-action {
        &:after {
            content: none;
        }
    }
}

.dropdown-toggle {
    &:after {
        vertical-align: middle;
        margin-left: 10px;
    }
}
#dropdown-user {
    color: $white;
    border-color: transparent;
    min-width: 60px;
    background-color: transparent !important;
    &:focus,
    &:hover {
        background-color: transparent;
        color: $white;
    }
    &:after {
        transition: all 0.3s ease;
    }
}
.show {
    #dropdown-user {
        &:after {
            color: $white;
            transform: rotate(180deg);
        }
    }
}

.btn-inline {
    margin-bottom: 1rem;
    height: 48px;
    line-height: 48px;
}

.btn.btn-fixed {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 100px;
    z-index: 999;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    min-width: 80px;
    line-height: 80px;
    padding: 0;
    &.fixed-right {
        right: 50px;
    }
    vertical-align: middle;
    .icon {
        display: inline-block;
        font-size: 45px;
    }
}

.btn.disabled,
.btn:disabled {
    opacity: 1;
    background-color: $gray-100;
    border-color: $gray-300;
    color: $gray-300;
}

.btn-light-violet {
    color: $violet;
    &:hover,
    &:focus,
    &:active {
        color: $violet !important;
    }
}
.btn-outline-violet {
    &:hover {
        color: $white;
        .icon {
            path {
                fill: $white;
            }
        }
    }
}
.btn-light {
    color: $text-color-dark;
    @include button-variant($box-funding-footer-border, $box-border-color);
}
.btn-violet {
    position: relative;
    &:disabled,
    .disabled {
        @include button-variant($violet, $violet);
        border: none;
        .arrow-right {
            path {
                fill: $white;
            }
        }
        &:before {
            position: absolute;
            content: "";
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85));
            top: -1px;
            left: -1px;
        }
        &:focus {
            box-shadow: none;
        }
    }
}
.btn-flat{
    min-width: auto;
    padding: 0;

}
.btn-flat-danger{
    color: $danger;
    &:hover{
        color: $danger;
        opacity: 0.7;
    }
}

.btn-skip{
    min-width: 92px;
    padding: 0;
}

.btn-white-opacity{
    background: rgba($white, 0.1);
    color: $white;
    &:hover,&:active, &.active,&:focus{
        background: rgba($white, 0.3);
        color: white;
    }
}