
@each $weight, $value in $font-weights {
  .font-weight-#{$weight} {
    font-weight: $value !important;
  }
}
@each $weight, $value in $font-weights-lato {
  .font-weight-lato-#{$weight} {
    font-weight: $value !important;
    font-family: $font-family-lato !important;
  }
}
@each $weight, $value in $font-weights-commissioner {
  .font-weight-commissioner-#{$weight} {
    font-weight: $value !important;
    font-family: $font-family-commissioner !important;
  }
}
@each $gray, $value in $grays {
  .text-gray-#{$gray} {
    color: $value !important;
  }
}
@each $gray, $value in $grays {
  .bg-gray-#{$gray} {
    background-color: $value;
  }
}
