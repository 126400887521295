.box-main-element-header {
    margin: -16px -16px 5px -16px;
    background-color: $main-element-header-bg;
    border-bottom: 1px solid $box-border-color;
    .box-main-element-header-wrapper {
        display: flex;
        .box-main-element-header-content {
            width: 50%;
            border-right: 1px solid $box-border-color;
            padding: 12px;
            flex-direction: column;
            justify-content: center;
            p {
                font-size: 14px;
                line-height: 20px;
                margin: 0;
            }
        }
        .box-main-element-header-level-criticaly {
            width: 50%;
            padding: 15px 14px 12px 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .level-criticaly-title {
                display: block;
                text-align: center;
                font-weight: $font-weight-bold;
                font-size: 14px;
                line-height: 20px;
                color: $text-color-dark;
                margin-bottom: 13px;
            }
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            .box-main-element-header-content {
                width: 100%;
                border-left: none;
                border-bottom: 1px solid $box-border-color;
                padding: 15px 14px 12px 14px;
            }
            .box-main-element-header-level-criticaly {
                width: 100%;
            }
        }
    }
}

.main-element-footer-charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 16px;
    .main-element-footer-item {
        width: 20%;
        padding: 0 8px;
        .main-element-footer-item-title {
            font-weight: $font-weight-bold;
            margin-top: 8px;
            font-size: 14px;
            line-height: 16px;
            display: block;
            text-align: center;
        }
    }
    &.two-columns{
        .main-element-footer-item{
            width: 33.3%;
            height: 81px;
            margin-bottom: 12px;
        }
    }
    @include media-breakpoint-down(md) {
        .main-element-footer-item {
            width: 25%;
            margin-bottom: 15px;
        }
    }
}

.box-main-element-footer {
    padding: 12px;
    display: flex;
    flex: 1;
    background: $box-funding-footer-bg;
    border: 1px solid $box-funding-footer-border;
    justify-content: center;
    .footer-title {
        span {
            display: block;
            font-weight: $font-weight-bold;
            font-size: 14px;
            color: $text-color-dark;
            white-space: nowrap;
        }
    }
    .checkbox-with-icon {
        margin-left: 16px;
        &:first-child {
            margin-left: 8px;
        }
    }
    @include media-breakpoint-down(md) {
        display: block;
        text-align: center;
    }
}
