.note-list {   
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px;
    max-height: 118px;
    height: 118px;
}

.note-item {
    display: flex;    
    textarea {
        color: $text-color-dark;
        font-size: 14px;
        line-height: 16px;
        margin: 0;
        min-height: 96px;
        padding: 8px;
        width: 100%;
        background: $main-element-header-bg;
        border: 1px solid $box-border-color;
        border-radius: 4px;
        min-height: 118px - 24px;
        max-height: 118px - 24px;
        resize: none
    }
}
.note-item-empty{
    text-align: center;
    line-height: 42px;
    font-size: 14px;
}
#box-brain-storming{
    height: 174px;
    .box-startup-body{
        height: 118px;
    }
}