.avatar-upload-container {
    position: relative;
    padding: 10px;
    text-align: center;
    input {
        visibility: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .avatar-upload {
        width: 80px;
        height: 80px;
        border: 1px solid $gray-200;
        border-radius: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gray-200;
        transition: all 0.3s ease;
        margin: 0 auto;
        span {
            font-size: 20px;
        }
    }
    .avatar-upload-choose {
        transition: all 0.3s ease;
        margin-top: 10px;
        display: block;
    }
    .avatar-upload-preview {
        transition: all 0.3s ease;
        width: 80px;
        height: 80px;
        border: 1px solid $gray-200;
        border-radius: 80px;
        background-size: cover;
        background-repeat: no-repeat;
        margin: 0 auto;
    }
    .avatar-upload-delete {
        position: absolute;
        top: 0;
        right: 0;
        transition: all 0.3s ease;
        &:hover {
            cursor: pointer;
            color: $danger;
        }
    }
    .avatar-upload-drag{
        opacity: 0;
        visibility: hidden;
        position: absolute;
        border-radius: 4px;
        z-index: 4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($primary,0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;
        span{
            color: $white;
            font-size: 16px;
        }
    }
    &.white{
        .avatar-upload{
            background-color: #fff;
        }   
    }
    &:hover {
        &:not(.hover-delete) {
            cursor: pointer;
            .avatar-upload,.avatar-upload-preview{
                color: $white;
                border-color: $primary;
                background-color: $primary;
            }
            .avatar-upload-choose {
                color: $primary;
                border-color: $primary;
            }
        }
    }
    &.on-dragging{
        .avatar-upload-drag{
            opacity: 1;
            visibility: visible;
        }
    }
}
