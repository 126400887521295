// Bootstrap
@import "~bootstrap/scss/bootstrap";
// Font Awesome
$fa-font-path: "../webfonts";
//Adapt the path to be relative to your main.scss file
@import "vendors/fontawesome-free/scss/fontawesome.scss";
//Include at least one of the below, depending on what icons you want.
//Adapt the path to be relative to your main.scss file
@import "vendors/fontawesome-free/scss/brands.scss";
@import "vendors/fontawesome-free/scss/regular.scss";
@import "vendors/fontawesome-free/scss/solid.scss";
@import 'react-toastify/scss/main';

@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@400;700;800&family=Lato:wght@900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');
