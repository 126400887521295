.strength-item {
    padding: 12px 16px;
    border-bottom: 1px solid $box-funding-footer-border;
    .item-title {
        display: block;
        font-weight: $font-weight-bold;
        font-size: 14px;
        line-height: 16px;
        color: $text-color-dark;
        margin-bottom: 6px;
    }
    .item-description {
        display: block;
        font-weight: $font-weight-normal;
        font-size: 12px;
        line-height: 16px;
        color: $text-color-light;
    }
    &:last-child {
        border-bottom-color: transparent;
    }
}

.strength-list{
    height: 63px * 2;
    min-height: 63px * 2;
    max-height: 63px * 2;
    overflow-y: auto;
}

.strength-item-empty{
    line-height: 63px;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px solid $box-funding-footer-border;


}
.strength-item-work-to-do{
    line-height: 63px;
    text-align: center;
    font-size: 14px;
    color: $text-color-dark;
    font-weight: $font-weight-bold;
    border-bottom: 1px solid $box-funding-footer-border;

}