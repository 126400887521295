#questions-pills {
    .nav-item {
        .nav-link {
            @include media-breakpoint-up(lg) {
                // min-width: 155px;
                justify-content: center;
            }
        }
    }
}


.question-items-list{
    .question-item{
        padding: 15px 15px 15px 30px;
        background-color: #eee;
        border-top: 10px solid $primary;  
        margin-bottom: 15px;      

    }
}

.first-scoring-question-container{
    .header{
        background-color: $primary;
    }
    &.has-error{
        .header{
            background-color: $danger;
        }
        .invalid-feedback{
            font-size: 18px;
            display: block;
        }
    }
}