.box-sub-element-header{
    margin: -16px -16px 5px -16px;
    background-color: $main-element-header-bg;
    border-bottom: 1px solid $box-border-color;
    p {
        padding: 12px;
        font-size: 16px;
        line-height: 20px;
        margin: 0;
    }
}

.box-sub-element-footer{
    padding: 12px;
    display: flex;
    flex: 1;
    background: $box-funding-footer-bg;
    border: 1px solid $box-funding-footer-border;
    justify-content:center;
    .footer-title {
        span {
            display: block;
            font-weight: $font-weight-bold;
            font-size: 14px;
            color: $text-color-dark;
            white-space: nowrap;
        }
    }
    .checkbox-with-icon {
        margin-left: 16px;
        &:first-child {
            margin-left: 8px;
        }
    }
    @include media-breakpoint-down(md) {
        display: block;
        text-align: center;
    }
}

.title-chart-sub-element{    
    font-weight: $font-weight-bold;
    font-size: 18px;
    line-height: 24px;
    display: block;   
    text-align: center;
    color: $text-color-dark;
    margin-top: -24px;
}