.swal2-popup {
    .swal2-header {
        .swal2-title {
            font-weight: $font-weight-medium;
        }
    }
    .swal2-actions{
        .btn{
            margin-left: 15px;
            &:first-child{
                margin-left: 0;
            }
        }

    }
}
