.rbt {
    .rbt-input-hint {
        @extend .form-control;
    }
    // border-color .15s ease-in-out, box-shadow .15s ease-in-out
    .rbt-input-container {
        position: relative;
        width: 100%;
        input {
            padding-right: 34px;
        }
        .rbt-aux {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            color: $gray-200;
            transition: all 0.15s ease-in-out;
            .svg-icon {
                transition: all 0.15s ease-in-out;
                svg {
                    fill: $gray-200;
                    transition: all 0.15s ease-in-out;
                    width: 24px;
                    height: 24px;
                }
            }
        }
        .rbt-aux-remove {
            width: 36px;
            height: 48px;
            right: 36px;
            top: 0;
            z-index: 2;
            pointer-events: all;
            .svg-icon {
                svg {
                    width: 20px;
                }
            }
            &:hover {
                cursor: pointer;
                .svg-icon {
                    svg {
                        fill: $primary;
                    }
                }
            }
        }
        .rbt-aux-caret,
        .rbt-aux-search {
            width: 36px;
            height: 39px;
            right: 0;
            top: 0;
            &:before {
                content: "";
                position: absolute;
                height: 60%;
                background-color: #ddd;
                width: 1px;
                left: 0;
                top: 20%;
            }
        }
        .rbt-aux-search {
            .svg-icon {
                svg {
                    width: 20px;
                }
            }
        }
        &.with-value {
            input {
                padding-right: 34px + 34px;
            }
        }

        &.focus {
            .rbt-aux {
                &:not(.rbt-aux-remove) {
                    .svg-icon {
                        svg {
                            fill: $primary;
                        }
                    }
                }
            }
            .rbt-aux-caret {
                span {
                    transform: rotate(-180deg);
                }
            }
        }
        &.is-invalid {
            .rbt-aux {
                &:not(.rbt-aux-remove) {
                    .svg-icon {
                        svg {
                            fill: $danger;
                        }
                    }
                    &:before {
                        background-color: $danger;
                    }
                }
            }
        }
    }

    .form-control {
        &.is-invalid {
            background-image: none;
        }
    }
    &.has-aux {
        .rbt-input-container {
            .rbt-aux {
                opacity: 0;
            }
        }
    }
}
.rbt-menu {
    padding: 0;
    li {
        border-top: 1px solid $gray-200;
        &:first-child {
            border-top: none;
        }
        .dropdown-item {
            padding: 0.5rem 1rem;
        }
    }
}
.typehead-address-container {
    .rbt-menu {
        li {
            .dropdown-item {
                .dropdown-item-content {
                    display: flex;
                    flex-direction: row;
                    .icon-container {
                        display: flex;
                        margin-left: -5px;
                        span {
                            color: $gray-300;
                        }
                    }
                    .address-container {
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
