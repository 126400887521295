.modal {
    &.fade.show {
        opacity: 1;
    }
    pointer-events: all !important;
    .modal-dialog {
        @include media-breakpoint-up(lg) {
            max-width: 800px;
        }
        .modal-content {
            border-radius: 8px;
            .modal-header {
                padding: 16px 22px;
                border-bottom: 1px solid $box-border-color;
                background-color: $box-funding-footer-border;
                color: $text-color-dark;
                .modal-title {
                    font-size: 18px;
                    font-weight: $font-weight-bold;
                }
                .custom-modal-close{
                    &:hover{
                        cursor: pointer;
                        opacity: 0.7;
                    }
                }
            }
            .modal-body {
                background-color: $box-funding-footer-bg;
                padding: 16px 24px;
                p.modal-body-text {
                    font-size: 18px;
                    white-space: pre-wrap
                }
            }
            .modal-footer {
                padding: 16px 24px;
                border-top-color: $white;
                border-top: 1px solid $box-border-color;
                .btn {
                    min-width: 136px;
                }
            }
        }
    }
    
}


#modal-master-session-history{
    .modal-body{
        padding-left: 0;
        padding-right: 0;
    }
}