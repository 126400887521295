@mixin placeholder {
    &.placeholder { @content }
    &:-moz-placeholder { @content }
    &::-webkit-input-placeholder { @content }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}
