.uploader{
    input {
        visibility: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
    }
 
}

.uploader-item{
    padding: 10px;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid $box-border-color;
    margin-bottom: 10px;
    .uploader-item-actions{        
        font-size: 14px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        > a {
            min-width: 60px;
        }
        a + a{
            margin-left: 8px;
        }
    }
    .uploader-item-name{
        display: block;
        font-size: 16px;
        color: $text-color-dark;
        word-break: break-word;
        white-space: pre-wrap;
        font-weight: $font-weight-medium;
    }
    .uploader-item-size{
        display: block;
        font-size: 14px;
    }
}