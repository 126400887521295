

@include media-breakpoint-up(lg) {
  
    .col-lg-45 {
        flex: 0 0 45%;
        max-width: 45%;
    }  
    .col-lg-55 {
        flex: 0 0 55%;
        max-width: 55%;
    }
}
@include media-breakpoint-up(xl) {
    .col-xl-43 {
        flex: 0 0 43%;
        max-width: 43%;
    }
    .col-xl-57 {
        flex: 0 0 57%;
        max-width: 57%;
    }
}
