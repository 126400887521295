#box-founders-external {
  .box-founders-invite-header {
    .column-btn {
      .btn + .btn {
        margin-left: 0;
        margin-top: 16px;
      }
    }
    @include media-breakpoint-up(lg) {
      .column-input {
        flex-basis: calc(50% - 150px / 2 - 140px / 2);
        max-width: calc(50% - 150px / 2 - 140px / 2);
      }
      .column-title {
        flex-basis: 150px;
        max-width: 150px;
      }
      .column-btn {
        flex-basis: 140px;
        max-width: 140px;
        &.two-btn {
          flex-basis: 316px;
          max-width: 316px;
          .btn + .btn {
            margin-left: 16px;
            margin-top: 0;
          }
          .btn {
            width: 140px;
            min-width: 140px;
          }
        }
      }
    }
  }
  .list-invitation-external-wrapper {
    background-color: $white;
  }

  .box-founders-actions {
    margin: 0;
    border-bottom: none;
    background-color: $white;
    .text-description {
      font-weight: $font-weight-normal;
      font-size: 16px;
      line-height: 20px;
    }
    &.with-line {
      border-top: 1px solid $box-funding-footer-border;
    }
  }
}
.category-list-external-rating {
  border-top: 1px solid $box-border-color;
}
.category-box-external-wrapper {
  border-bottom: 1px solid $box-border-color;
  &:last-child {
    border-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    flex-basis: 100%;
  }
  @include media-breakpoint-up(lg) {
    border-bottom: 0;
    border-right: 1px solid $box-border-color;
    &:last-child {
      border-right: 0;
    }
  }
}
.category-box-external-rating {
  height: 100%;
  .category-title {
    font-style: normal;
    font-weight: $font-weight-black;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: $violet;
    padding: 12px 18px;
    background: $box-funding-footer-border;
    border-bottom: 1px solid $box-border-color;
  }
  .list-types {
    padding: 10px 0;
    .list-type-item {
      padding: 6px 16px;
    }
  }
}
