.form-box-login {
    max-width: 390px;
    margin: 0 auto;
    padding-top: 100px;
    min-height: 100vh;
    .form-box-header{
        display: flex;
        margin-bottom: 15px;
        img{
            width: 100px;
        }
        h4{
            font-size: 28px;
            font-weight: $font-weight-black;
            margin-left: auto;
            align-self: flex-end;
        }
        @include media-breakpoint-down(md) {
            flex-direction: column;
            justify-content: center;
            img{
                margin: 0 auto;
            }
            h4{
                margin-top: 15px;
                margin-left: 0;
                font-size: 18px;
                text-align: center;
                align-self: center;
            }
        }
        
    }
    .form-box-login-title {
        color: $info;
        font-size: 18px;
        padding: 15px 0px;
        font-weight: $font-weight-medium;
    }
    .help-body {
        max-width: 400px;
        margin: 0 auto;
    }
    .recover-password {
        color: $white;
    }
    @include media-breakpoint-down(md) {
        padding: 50px;
    }
    &.restore-password{
        max-width: 450px;
    }
}


