.go-premium-header {
  background-color: $violet;
  padding: 64px 24px 108px 24px;
  text-align: center;
  .go-premium-header-title {
    color: $white;
    display: block;
    font-weight: $font-weight-bold;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .go-premium-header-description {
    color: $white;
    display: block;
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
  }
}
