.priorization-list {
    .priorization-header {
        color: $white;
        font-size: 12px;
        border-radius: 4px;
        line-height: 16px;
        font-weight: $font-weight-commissioner-bold;
        background-color: $priorization-header;
        span {
            display: block;
            text-align: center;
            padding: 8px 0;
        }
    }
    .priorization-content {
        padding: 34px 0;
        background: $box-funding-footer-bg;
        border: 1px solid $box-funding-footer-border;
        border-radius: 4px;
        margin-top: 8px;
        .priorization-item-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;
            min-height: 100%;
            position: relative;
            .priorization-item {
                color: $dark;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 16px;
                text-align: center;
                a {
                    color: $dark;
                }
            }
            &.with-border-right {
                &:before {
                    content: "";
                    position: absolute;
                    right: -4px;
                    top: 16px;
                    bottom: 16px;
                    width: 1px;
                    background-color: $box-border-color;
                }
            }
        }
    }
    & + .priorization-list {
        margin-top: 16px;
    }
}
#tab-priorization-container {
    .tab-content {
        .tab-pane {
            padding: 0;
            min-height: 254px;
        }
    }
}
.priorization-item-block {
    padding: 16px;
    border-bottom: 1px solid $box-border-color;

    .priorization-item-title-content {
        display: flex;
        margin-bottom: 8px;
        .item-title {
            width: calc(100% - 76px);
            font-weight: $font-weight-bold;
            font-size: 16px;
            line-height: 24px;
            color: $text-color-dark;
            padding-right: 10px;
        }
        .btn {
            min-width: 76px;
            margin-left: auto;
        }
    }
    .priorization-item-description-content {
        p {
            margin: 0;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        }
    }
    &:last-child{
        border-bottom: 0;
    }
}
.priorization-item-block-empty{
    line-height: 85px;
    text-align: center;
    color: $text-color-light;
    font-size: 16px;
}
