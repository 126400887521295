.startup-gauge-chart {
    margin: 0 auto;
    .donutText {
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: $font-weight-medium;
        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }
    }
    .startup-gauge-chart-container {
        margin: 0 auto;
    }
    @include media-breakpoint-down(md) {
        max-width: 300px;
    }
}

.startup-middle-gauge-chart {
    margin: 0 auto;
    .donutText {
        font-family: $font-family-lato;
        font-weight: 900;
        font-size: 10px;
        letter-spacing: 1px;
        font-weight: $font-weight-medium;
        @include media-breakpoint-up(lg) {
            font-size: 10px;
        }
    }
    .needle-wrapper.default{
        .needle {
            fill: $needle;
            stroke: $needle;
        }
        .needle-center {
            fill: $needle;
            stroke: $needle;
        }
        .needle-center-white {
            fill: $white;
        }
    }
    .needle-wrapper.internal{
        .needle {
            fill: $needle-yellow;
            stroke: $needle-yellow;
        }
        .needle-center {
            fill: $needle-yellow;
            stroke: $needle-yellow;
        }
        .needle-center-white {
            fill: $white;
        }
    }
    .needle-wrapper.external{
        .needle {
            fill: $box-funding-footer-border-inner;
            stroke: $box-funding-footer-border-inner;
        }
        .needle-center {
            fill: $box-funding-footer-border-inner;
            stroke: $box-funding-footer-border-inner;
        }
        .needle-center-white {
            fill: $white;
        }
    }

    .startup-middle-gauge-chart-container {
        margin: 0 auto;
    }
    @include media-breakpoint-down(md) {
        max-width: 300px;
    }
}

.startup-nested-pie-chart {
    .startup-nested-pie-chart-container {
        margin: 0 auto;
        .ring {
            .donut-arc-background,
            .donut-arc {
                &:hover {
                    cursor: pointer;
                }
            }
            &.mouseover {
                .border {
                    fill: $dark-violet;
                }
            }
        }
    }
}

// Tooltips
.d3-tip-simple {
    background-color: $dark-violet;
    color: $white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 24px;
    font-weight: $font-weight-bold;
}
// Progress Bar
.startup-progress-chart-list {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    .startup-progress-chart{
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.startup-progress-chart {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 21px;
    .label {
        font-size: 16px;
        color: $text-color-dark;
        font-weight: $font-weight-medium;
    }
    .progress-bar-wrapper {
        margin-left: auto;
        .progress-text {
            font-family: $font-family-commissioner;
            font-style: normal;
            font-weight: $font-weight-commissioner-extra-bold;
            font-size: 10px;
            letter-spacing: 0.04em;
            fill: $white;
            text-transform: uppercase;
        }
    }
}
// Progress bar inline
.startup-progress-chart-inline {
    .startup-progress-chart-inline-header {
        display: flex;
        margin-bottom: 2px;
        .label {
            font-size: 12px;
            line-height: 16px;
        }
        .percentage {
            margin-left: auto;
            font-weight: $font-weight-bold;
            font-size: 14px;
            line-height: 16px;
        }
        svg{
            vertical-align: bottom;
        }
    }
}

.startup-progress-percentage-chart{
    .progress-bar-wrapper {
        margin-left: auto;
        .progress-text {
            font-weight: $font-weight-bold;
            font-size: 10px;            
            fill: $white;
            text-transform: uppercase;
        }
    }
}


#chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    visibility: hidden;
}   

.chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}