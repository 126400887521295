.admin-footer {
    min-height: 90px;
    background-color: $primary;
    padding: 18px 0;
    img {
        max-width: 151px;
    }
    .navbar-brand{
        margin: 0;
    }
    p{
        width: 100%;
        display: block;
        margin: 0;
        color:$white;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
    }
    a{
        color: $white;
    }
    @include media-breakpoint-down(md) {
        min-height: 125px;
        img {
            max-width: 100px;
        }
        p{
            text-align: center;
            margin-top: 15px;
        }
    }
}
