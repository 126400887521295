.dropdown-menu-funding {
    .dropdown-item {
        display: flex;
        align-items: center;
        .indicator {
            display: inline-block;
            margin-right: 10px;
            width: 15px;
            height: 15px;
            border-radius: 15px;
            background-color: $white;
            border: 1px solid $dark;

            &.not_fundable {
                background-color: $admin-funding-not-fundable;
            }
            &.work_to_do {
                background-color: $admin-funding-work-to-do;
            }
            &.looking_good {
                background-color: $admin-funding-looking-good;
            }
            &.go_get_them {
                background-color: $admin-funding-go-get-them;
            }
        }
    }
}
