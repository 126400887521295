.locked-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: rgba($white, 0.4);
    backdrop-filter: blur(2px);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn{
        min-width: 73px;
    }
}
