.file-list {
    min-height: 64px * 3;
    max-height: 64px * 3;
    overflow-x: hidden;
    overflow-y: auto;
}
.file-item {
    position: relative;
    border-bottom: 1px solid $box-funding-footer-border;

    .icon-file {
        position: absolute;
        left: 11px;
        display: block;
        top: 50%;
        margin-top: -(32px / 2);
    }
    .file-download {
        position: absolute;
        right: 14px;
        top: 14px;
        display: block;
        top: 50%;
        margin-top: -12px;
        .rs-loader-wrapper {
            position: absolute;
            left: -3px;
            top: 3px;
            opacity: 0;
            visibility: hidden;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .file-menu-action {
        position: absolute;
        right: 14px;
        top: 14px;
        display: block;
        top: 50%;
        margin-top: -12px;
        .dropdown-toggle{
            padding: 0;
            background-color: transparent;
            min-width: 0;
            border: none;
            line-height: 20px;
            &:hover,&:focus{
                background-color: transparent;
            }
            &:after{
                display: none;
            }
        }
        &:hover {
            cursor: pointer;
        }
    }
    .file-item-content {
        padding-left: 56px;
        padding-right: 26px;
        padding-top: 16px;
        padding-bottom: 16px;
        .file-name {
            display: block;
            color: $text-color-dark;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 4px;
        }
        .file-date-and-size {
            display: block;
            color: $text-color-light;
            font-size: 12px;
            line-height: 12px;
        }
    }
    &.is-fetch {
        .icon-download {
            opacity: 0;
        }
        .rs-loader-wrapper {
            opacity: 1;
            visibility: visible;
        }
    }
    &.master-session{
        .file-item-content {
            padding-left: 56px;
            padding-right: 26px;
            padding-top: 16px;
            padding-bottom: 16px;
            .file-name {
                display: block;
                color: $text-color-dark;
                font-size: 14px;
                line-height: 16px;
                margin-bottom: 4px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
        }
        &:hover{
            cursor: pointer;
        }
    }
    
}

.file-item-empty {
    position: relative;
    border-bottom: 1px solid $box-funding-footer-border;
    padding-left: 56px;
    padding-right: 26px;    
    span {
        line-height: 64px;
        text-align: center;
        display: block;
        font-size: 14px;
    }
}
