._loading_overlay_wrapper {
    ._loading_overlay_overlay {
        position: fixed;
        background-color: rgba($white, 0.7);
        color: $dark;
        ._loading_overlay_content {
            font-size: 24px;
            ._loading_overlay_spinner {
                display: flex;
                justify-content: center;
                margin-bottom: 15px;
            }
        }
    }
}

.rs-loader-wrapper{
    
    &.rs-loader-primmary{
        .rs-loader{
            .rs-loader-spin{            
                &:after{
                    border-color: $arc-grey transparent transparent;
                }
                &:before{
                    border-color: rgba($violet,1);
                }
            }   
        }
    }
}