.track-item {
  position: relative;
  border-bottom: 1px solid $box-border-color;
  display: block;
  .collapse-btn {
    transition: all 0.3s ease;
    position: absolute;
    left: 7px;
    top: 50%;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 34px;
    span {
      display: inline-block;
      svg {
        vertical-align: baseline;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  .track-item-content {
    padding: 16px 16px 16px 64px;
    .track-item-information {
      display: flex;
      align-items: center;
      .track-order {
        font-weight: $font-weight-normal;
        font-size: 18px;
        line-height: 16px;
        padding: 4px 16px;
        background-color: $good;
        border-radius: 4px;
        color: $white;
        margin-right: 24px;
        min-width: 120px;
        text-align: center;
      }
      .track-title {
        font-weight: $font-weight-bold;
        font-size: 18px;
        line-height: 24px;
        color: $text-color-light;
      }
    }
  }
  &.collapse-show {
    .collapse-btn {
      transform: rotate(90deg);
    }
  }
}
.track-item-accordion{
    position: relative;
    background-color: $box-funding-footer-border;
    height: auto;
    transition: all 0.3s linear;
    &.collapse-hidden {
        padding: 0;
        height: 0;
        margin: 0;
        overflow: hidden;
        border-width: 0px;
    }
}