.list-guests {
  padding: 16px 32px;
}
.list-guest-wrapper {
  border: 1px solid $box-border-color;
  border-radius: 4px;
}
.guest-item-empty {
  background-color: $white;
  padding: 0 16px;
  line-height: 58px;
  font-size: 14px;
}
.guest-item {
  background-color: $white;
  padding: 16px;
  border-bottom: 1px solid $box-border-color;
  .guest-item-name,
  .guest-item-group,
  .guest-item-email {
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 16px;
    color: $text-color-dark;
  }
  .btn {
    margin-left: 22px;
    min-width: auto;
    padding: 0;
  }
  &:last-child {
    border-bottom: none;
  }
}
// External Item
.guest-item.guest_external, 
.guest-item.group-admin-item
{
  padding: 0;
  .collapse-btn {
    transition: all 0.3s ease;
    display: inline-block;
    &:hover {
      cursor: pointer;
    }
  }
  .guest-item-wrapper {
    padding: 16px;
    &.collapse-show {
      .collapse-btn {
        transform: rotate(90deg);
      }
    }
  }
}
.guest-item-accordion {
  background-color: $box-funding-footer-border;
  height: auto;
  &.collapse-hidden {
    padding: 0;
    height: 0;
    overflow: hidden;
    border-width: 0px;
  }
}
.guest-item-invitation{
  padding: 10px 0px;
  padding: 16px;
  border-bottom: 1px solid $box-funding-footer-border-inner;
  .btn {
    margin-left: 22px;
    min-width: auto;
    padding: 0;
  }
  .guest-item-invitation-types,
  .guest-item-invitation-date{
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 16px;
    color: $text-color-dark;
  }
  .guest-item-invitation-types{
    word-break: break-word;
  }
  &:last-child{
    border-bottom: none;
  }
}
// List invitations
.list-guest-external {
  display: flex;
  flex-wrap: wrap;
}
.guest-external-item {
  width: 33.33%;
  padding: 16px;
  // border-right: 1px solid $box-border-color;
  // border-bottom: 1px solid $box-border-color;
  .guest-item-name,
  .guest-item-email {
    display: block;
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 16px;
    color: $text-color-dark;
  }
  .guest-item-name {
    font-weight: $font-weight-medium;
  }
  // &:nth-child(3n) {
  //   border-right: none;
  // }
  @include media-breakpoint-down(md) {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid $box-border-color;
    &:last-child {
      border-bottom: none;
    }
  }
}
