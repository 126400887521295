body {
  overflow-x: hidden;
}
#main {
  overflow-x: hidden;
}
.main-content-admin {
  background-color: $startup-light;
  min-height: 100vh;
  .main-container-wrapper {
    transition: all 0.3s ease;
    .main-container {
      padding-top: 70px;
      margin-top: 15px;
      margin-bottom: 53px;
      min-height: 100vh;
    }
  }
}
// Dashboard
.main-content-dashboard {
  background-color: $startup-light;
  min-height: 100vh;
  .main-container-wrapper {
    // transition: all 0.3s ease;
    padding-left: 200px;
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
    .main-container {
      padding-top: 32px;
      padding-bottom: 24px - 16px;
      min-height: 100vh;
      .container-fluid {
        padding: 0 24px;
        @include media-breakpoint-down(md) {
          padding-left: 48px;
        }
      }
    }
  }
  &#go-premium {
    .main-container-wrapper {
      .main-container {
          padding-top: 0;
          .container-fluid{
              padding: 0;
              @include media-breakpoint-down(md) {
                padding-left: 24px;
              }
          }
      }
    }
  }
}
.main-content-questionnaire {
  background-color: $startup-light;
  min-height: 100vh;
  .main-container-wrapper {
    .main-container {
    }
  }
}
// Questions
.main-content-questions {
  background-color: $startup-light;
  min-height: 100vh;
  .main-container {
    padding-top: 70px;
    margin-top: 15px;
    margin-bottom: 53px;
    min-height: 100vh;
  }
}

#login-container {
  height: 100%;
  background-color: $primary;
  position: relative;
  overflow: hidden;
}

.main-content-dashboard-lead{
  .main-container-wrapper {
    padding-left: 200px;
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
    .main-container {
      padding-top: 72px;
      padding-bottom: 24px - 16px;
      margin-top: 16px;
      min-height: 100vh;
      .container-fluid {
        padding: 0 24px;
        @include media-breakpoint-down(md) {
          padding-left: 48px;
        }
      }
    }
  }
}