.multiple-step-navigation {
    background-color: $mutilple-steps-nav;
    padding: 24px;
    display: flex;
    justify-content: center;
    .nav {
        .nav-link {
            padding: 0;
            margin-right: 29px;
            font-size: 14px;
            color: $text-color-light;
            line-height: 16px;
            span.link-text {
                display: inline-block;
                padding-right: 29px;
            }
            .arrow-right {
                path {
                    fill: $text-color-light;
                }
            }
            &.active {
                text-shadow: 0.5px 0 0 $multiple-steps-active;
                color: $multiple-steps-active;
                .arrow-right {
                    path {
                        fill: $multiple-steps-active;
                    }
                }
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;
                .arrow-right{
                    display: none;
                }
            }
        }
    }
}
.multiple-step-wrapper {
    .multiple-step-main-content {
        padding: 40px 0;
        display: flex;
        flex-direction: column;
    }
}

.multiple-step-title {
    font-weight: $font-weight-medium;
    margin-bottom: 16px;
    text-align: center;
    color: $violet;
}
.multiple-step-description {
    font-size: 16px;
    line-height: 24px;
    color: $dark;
    text-align: center;
    max-width: 900px;
    margin: 0 auto 16px auto;
}

.multiple-step-description-black{
    margin: 16px 0;
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: 16px;
    line-height: 24px;    
    color: $dark;
}

.multiple-step-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.progress-multiple-step{
    display: flex;
    width: 100%;
    height: 2px;
    .progress-multiple-step-bar{
        width: 0;
        transition: width 0.3s ease;
        background-color: $violet;
    }
}