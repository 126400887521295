.rs-slider-bar {
    height: 16px;
    padding: 3px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background-color: $white;
    &:hover {
        background-color: $white;
    }
}
.rs-slider-progress-bar {
    transition: background-color 0.3s ease-in-out;
    height: 12px;
    background: $poor;
    border-radius: 3px;
    margin-left: 2px;
    top: 2px;
    background-color: $poor;
}
.rs-slider {
    &.status-poor {
        .rs-slider-progress-bar {
            background-color: $poor;
        }
    }
    &.status-fair {
        .rs-slider-progress-bar {
            background-color: $fair;
        }
    }
    &.status-good {
        .rs-slider-progress-bar {
            background-color: $good;
        }
    }
}

.rs-slider-handle {
    top: 0;
    height: 16px;
    width: 12px;
    &:after {
        content: "";
        position: absolute;
        height: 8px;
        width: 2px;
        background-color: #121024;
        left: -1px;
        top: 4px;
    }
    &:before {
        border-width: 1px;
        height: 16px;
        width: 12px;
        position: absolute;
        border-radius: 2px;
        background-color: $white;
        border-color: $text-color-dark;
    }
    &:hover {
        cursor: pointer;
    }
}

.form-group-slider {
    label {
        &.status-poor {
            color: $poor;
        }
        &.status-fair {
            color: $fair;
        }
        &.status-good {
            color: $good;
        }
    }
}
