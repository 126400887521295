.tab-content {
    .tab-pane {
        padding: 24px;
        &.fade.show{
            opacity: 1;
            pointer-events: all;
        }
        
    }
}
.tabs-answers-container {
    .nav-pills-primary {
        padding: 30px 10px;
        border-radius: 4px;
        background: $white;
        box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
        @include media-breakpoint-down(md) {
            padding: 15px 10px;
            margin-bottom: 15px;
        }
    }
    .tab-content-primary {
        // padding: 30px 46px;
        border-radius: 4px;
        background: $white;
        box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
        min-height: calc(100vh - (150px));
        @include media-breakpoint-down(md) {
            padding: 25px 20px;
        }
    }
    .tab-content-secondary {
        margin-top: 20px;
    }
}

.nav-pills-with-error {
    .nav-link {
        display: flex;
        align-items: center;
        .icon-error {
            display: none;
            margin-left: 10px;
        }
    }
    .nav-link.has-error {
        color: $danger;
        .icon-error {
            display: block;
        }
    }
}

// Tabs Theme
.nav-tabs {
    background-color: $white;
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
    .nav-item {
        .nav-link {
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 14px;
            line-height: 20px;
            color: $text-color-light;
            border-bottom-width: 2px;
            border-bottom-color: transparent;
            border-top: none;
            border-left: none;
            border-right: none;
            &:hover {
                border-bottom-color: lighten($text-color-light, 40%);
            }
            &.active {
                background-color: $white;
                font-weight: $font-weight-medium;
                border-bottom-color: $text-color-light;
            }
        }
    }
    &.nav-tabs-2{
        .nav-item{
            .nav-link{
                padding-left: 24px;
                padding-right: 24px;
                display: inline-block;
                &:hover {
                    color: lighten($violet, 40%);
                    border-bottom-color: lighten($violet, 40%);
                }
                &.active {
                    color: $violet;
                    border-bottom-color: $violet;
                }
            }
        }
    }
}
