@keyframes Slick-FastSwipeIn {
  0% {
    transform: rotate3d(0, 1, 0, 150deg) scale(0) perspective(400px);
  }
  100% {
    transform: rotate3d(0, 1, 0, 0deg) scale(1) perspective(400px);
  }
}

@include keyframes( dotty)  {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}
