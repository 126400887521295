.rw-datetime-picker {
    @extend .form-control;
    padding-right: 0;
    .rw-widget-picker {
        height: 100%;
        position: relative;
        border: none;
        box-shadow: none;
        padding: 0;
        .rw-widget-input {
            box-shadow: none;
            padding: 0;
            box-shadow: none;
            &:hover {
                box-shadow: none;
            }
        }
        &:hover {
            box-shadow: none;
        }
        .rw-select {
            height: 100%;
            padding: 0;
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
            border: none;
            padding: 0;
            &:before {
                content: "";
                position: absolute;
                height: 20px;
                background-color: $gray-200;
                width: 1px;
                left: 0;
                top: 50%;
                margin-top: -(20px / 2);
            }
            button {
                width: 36px;
            }
            &:hover {
                background-color: transparent;
            }
        }
    }
    &.rw-state-focus {
        border-color: $primary;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba($primary, 0.25);
        .rw-widget-picker {
            .rw-widget-input {
                box-shadow: none;
                padding: 0;
                box-shadow: none;
            }
        }
    }
    &.is-invalid {
        background-image: none;
        border-color: $danger;
        padding-right: 0;
        .rw-widget-picker {
            .rw-select {
                .rw-btn {
                    .rw-i {
                        color:$danger;
                    }
                }
                &:before{
                    background-color: $danger;
                }
            }
        }
        &.rw-state-focus{
            box-shadow: 0 0 0 0.2rem rgba($danger, 0.25);
        }
    }
}
