.nav-pills-secondary {
    padding-bottom: 15px;
    border-bottom: 1px solid $gray-200;
}
.nav-pills-tertiary {
    padding-bottom: 15px;
    border-bottom: 1px solid $gray-200;
    .nav-item {
        .nav-link {
            &.active {
                background-color: $secondary;
            }
        }
    }
}
.nav-pills {
    .nav-item {
        .nav-link {
            display: flex;
            font-weight: $font-weight-medium;
            color: $gray-600;
            .error-icon {
                display: none;
                color: $white;
                margin-left: 5px;
            }
            &.active {
                color: $white;
            }
            &.is-invalid {
                .error-icon {
                    display: block;
                }
                &:not(.active) {
                    .error-icon {
                        color: $danger;
                    }
                }
            }
            &.disabled {
                color: $dark;
                background-color: transparent;
                opacity: 0.5;
            }
        }
    } 
}
