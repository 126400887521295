.questionnaire-instructions {
  max-width: 606px;
  margin: 0 auto 24px auto;
  list-style: none;
  padding: 0;
  li {
    padding-left: 16px;
    font-size: 16px;
    line-height: 24px;
    color: $dark;
    position: relative;
    margin-bottom: 16px;
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      background-color: $questionnaire-intructions-box-color;
      position: absolute;
      left: 0;
      top: 8px;
    }
  }
}

.radio-group-questions {
  max-width: 900px;
  padding-top: 12px;
  margin: 0px auto 32px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .radio-group-title {
    font-weight: $font-weight-medium;
    margin-bottom: 24px;
    text-align: center;
    color: $violet;
  }
  .radio-group-questions-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .radio-group-item {
      width: auto;
      display: inline-block;
      margin-bottom: 12px;
      .custom-radio,
      .custom-checkbox {
        display: inline-block;
        padding-left: 0;
        height: auto;
        .custom-control-label {
          font-weight: $font-weight-normal;
          background: $white;
          border: 1px solid $box-border-color;
          border-radius: 4px;
          padding: 12px;
          font-size: 14px;
          line-height: 24px;
          color: $dark;
          &:before,
          &:after {
            content: none;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .custom-control-input:checked ~ .custom-control-label {
          color: $violet;
          border-color: $violet;
          background: $mutilple-steps-nav;
          text-shadow: 0.5px 0 0 $violet;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.in-row {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      .radio-group-item {
        margin-left: 24px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.btn-finish-later-questionnaire {
  position: fixed;
  top: 16px;
  left: 16px;
}
.radio-group-questions-number {
  margin-bottom: 32px;
  .radio-group-title {
    font-weight: $font-weight-medium;
    margin-bottom: 24px;
    text-align: center;
    color: $violet;
  }
}
.radio-group-questions-options-numbers {
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  .radio-group-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .custom-radio,
    .custom-checkbox {
      display: inline-block;
      padding-left: 0;
      height: auto;
      .custom-control-label {
        width: 48px;
        height: 48px;
        border-width: 4px;
        line-height: 40px;
        background: $white;
        border: 4px solid;
        border-radius: 4px;
        text-align: center;
        font-size: 20px;
        text-align: center;
        color: $dark;
        &:before,
        &:after {
          content: none;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .custom-control-input:checked ~ .custom-control-label {
        text-shadow: none;
      }
    }
    .radio-group-after-label {
      line-height: 19px;
      margin-top: 10px;
      white-space: pre-line;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 19px * 2;
    }

    @each $index, $value in $numbers-colors {
      &#radio-group-item-#{$index} {
        .custom-radio,
        .custom-checkbox {
          .custom-control-label {
            border-color: $value;
          }
          .custom-control-input:checked ~ .custom-control-label {
            background-color: $value;
            color: $white;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      flex-basis: 20%;
       margin-bottom: 24px;
      align-self: flex-start;
      &:first-child {
        margin-top: 0;
      }
    }
    @include media-breakpoint-down(sm) {
      flex-basis: 50%;
       margin-bottom: 24px;
      align-self: flex-start;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
