.accordion {
  .card-header {
    .accordion-button {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      line-height: 24px;
      font-weight: $font-weight-bold;
      color: $text-color-dark;
      text-align: left;
      border: 0;
      border-radius: 0;

      &:after {
        transition: all 0.3s ease;
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EChevron Down%3C/title%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 184l144 144 144-144'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 1.25rem;
      }
    }
    &:not(.collapsed) {
      .accordion-button {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
