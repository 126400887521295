.list-group-files {
    margin-top: 15px;
    .list-file {
        display: flex;
        align-items: center;
        .list-file-icon {
            margin-right: 10px;
            color: $gray-200;
        }
        .list-file-remove {
            margin-left: auto;
            color: $gray-200;
            width: 24px;
            height: 24px;
            &:hover {
                color: $primary;
            }
        }
    }
}

.list-group-sortable {
    .list-group-item {
        padding: 10px 0;    
        border: none ;
        .sortable-addon{
            &:hover{
                cursor: move;
            }
        }
    }
}
.list-item-sort {
    background-color: transparent;
    padding: 10px 0;
    margin: 0;
    border: none;
}
