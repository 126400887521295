.challenge-filter {
    padding: 12px 16px;
    background: $box-funding-footer-bg;
    border: 1px solid $box-funding-footer-border;
   
}

.challenge-list {
    overflow-x: hidden;
    overflow-y: auto;
    
    // @include media-breakpoint-down(md) {
    //     max-height: 63px * 5;
    //     height: 63px * 5;
    // }
    // @include media-breakpoint-up(lg) {
    //     max-height: calc(100% - 54px);
    //     height: calc(100% - 54px);
    // }
    // @include media-breakpoint-up(xl) {
    //     max-height: calc(100% - 54px);
    //     height: calc(100% - 54px);
    // }

}
.challenge-item {
    position: relative;
    border-bottom: 1px solid $box-border-color;
    display: block;
    .collapse-btn {
        transition: all 0.3s ease;
        position: absolute;
        left: 7px;
        top: 14px;     
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 34px;
        span {
            display: inline-block;
            svg {
                vertical-align: baseline;
            }
        }
        &:hover{
            cursor: pointer;
        }
    }
    .challenge-item-content {
        padding: 12px 16px 12px 48px;
        .challenge-title {
            // @extend .text-truncate;
            display: block;
            display: block;
            font-weight: $font-weight-bold;
            font-size: 14px;
            line-height: 16px;
            color: $text-color-dark;
            margin-bottom: 6px;
            &:hover{
                cursor: pointer;
                text-decoration: underline
            }
        }
        .challenge-description {
            // @extend .text-truncate;
            display: block;
            display: block;
            font-size: 12px;
            line-height: 16px;
            color: $text-color-light;
        }
        .challenge-level {
            display: flex;
            height: 16px;
            margin-bottom: 6px;
            justify-content: flex-end;
        }
        .challenge-due-date {
            display: flex;
            align-items: center;
            height: 16px;
            .calendar-icon {
                margin-right: 8px;
            }
            .due-date {
                display: inline-block;
                font-size: 12px;
                line-height: 16px;
            }
        }
        @include media-breakpoint-up(md) {
            .challenge-level,
            .challenge-due-date {
                text-align: right;
            }
        }
    }
    .title-empty {
        line-height: 62px;
        display: block;
        text-align: center;
        color: $text-color-light;
    }
    &.collapse-show{
        .collapse-btn{
            transform: rotate(90deg);
        }
    }
}
.challenge-item-accordion {
    position: relative;
    background-color: $box-funding-footer-border;
    max-height: 500px;
    transition: all 0.3s ease;
    font-size: 14px;
    line-height: 16px;
    .table-cell {
        padding: 12px 16px 12px 48px;
        flex: 1 100%;
        .test-description {
            margin: 0;
        }
        .test-progress-content {
            margin-top: 12px;
            display: flex;
            align-items: center;
            width: 100%;
            .test-progress-content-metric {
                display: flex;
                width: calc(100% - 141px - 61px);
                padding-left: 16px;
                padding-right: 32px;
                .test-progress-content-metric-wrapper {
                    width: 100%;
                    .kpi-metric-content {
                        .kpi-metric-content-title {
                            display: inline-block;
                            margin-left: 8px;
                            > span {
                                margin-left: 8px;
                            }
                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
            .test-progress-content-due-date {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .due-date {
                    > span {
                        margin-left: 8px;
                    }
                }
                .btn {
                    min-width: 70px;
                    margin-left: 16px;
                }
            }
        }
    }
    &.collapse-hidden {
        padding: 0;
        max-height: 0;
        margin: 0;
        overflow: hidden;
        border-width: 0px;
    }
}
.badge-challenge {
    display: inline-block;
    line-height: 16px;
    padding: 0 8px;
    font-size: 10px;
    line-height: 16px;
    font-family: $font-family-commissioner;
    font-weight: $font-weight-commissioner-bold;
    border-radius: 61px;
    &.good {
        background-color: rgba($good, 0.16);
        color: $good;
    }
    &.poor {
        background-color: rgba($poor, 0.16);
        color: $poor;
    }
    &.fair {
        background-color: rgba($fair, 0.16);
        color: $fair;
    }
    &.category {
        background-color: $high;
        color: $white;
    }
    & + .badge-challenge {
        margin-left: 8px;
    }
}

.badge-challenge-status {
    color: $white;
    .icon-status {
        display: inline-block;
        margin-right: 4px;
        svg {
            vertical-align: text-top;
        }
    }
    &.passed, &.answered {
        background-color: $good;
    }
    &.in_progress {
        background-color: $in-progress;
    }
    &.failed, &.sent {
        background-color: $poor;
    }
    &.proposed {
        background-color: $violet;
    }
}
// Challenges Table
.challenge-table-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}
.challenge-table {
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    .cell-collapse {
        flex: 0 0 32px;
        text-align: center;
    }
    .cell-title {
        flex: 1 10%;
    }
    .cell-description {
        flex: 1 56%;
    }
    .cell-action {
        flex: 0 0 61px;
    }
    .cell-empty {
        text-align: center;
        flex: 1 100%;
    }
    .thead {
        .table-cell {
            font-weight: $font-weight-bold;
            font-size: 10px;
            line-height: 16px;
            align-items: center;
            letter-spacing: 0.06em;
            color: $priorization-header;
            padding: 8px 8px;
            background-color: $box-funding-footer-bg;
            border-bottom: 1px solid $box-border-color;
        }
    }

    .body {
        position: relative;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $box-border-color;
        overflow: hidden;
        background-color: $box-funding-footer-bg;
        .table-cell {
            font-size: 14px;
            line-height: 16px;
            color: $text-color-dark;
            padding: 8px 8px;

            .btn {
                min-width: 45px;
                padding: 0;
            }
            &.cell-collapse {
                text-align: center;
                .collpase-link {
                    .arrow-right {
                        display: block;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
        &.without-background {
            background-color: $white;
        }
        .locked {
            position: absolute;
            width: 100%;
            height: 100%;
        }
        &:not(.collapse) {
            .cell-collapse {
                .collpase-link {
                    .arrow-right {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &.empty {
            color: $text-color-light;
            background-color: $white;
        }
    }
    .collpased-row {
        position: relative;
        background-color: $box-funding-footer-border;
        max-height: 500px;
        transition: all 0.3s ease;
        .table-cell {
            padding: 12px 16px;
            flex: 1 100%;
            p {
                margin: 0;
            }
        }
        &.collapse {
            padding: 0;
            max-height: 0;
            margin: 0;
            overflow: hidden;
            border-width: 0px;
        }
    }
}
// My Challenges Table
.my-challenge-table-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}
.my-challenge-table {
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    .cell-collapse {
        flex: 0 0 32px;
        text-align: center;
    }
    .cell-title {
        flex: 1 10%;
        @include media-breakpoint-up(xl) {
            flex: 1 17%;
        }
    }
    .cell-description {
        flex: 1 20%;
        @include media-breakpoint-up(xl) {
            flex: 1 30%;
        }
    }
    .cell-critical {
        flex: 0 0 60px;
        text-align: center;
    }
    .cell-blocking {
        flex: 0 0 70px;
        text-align: center;
    }
    .cell-priority-level {
        flex: 0 0 107px;
        text-align: center;
    }
    .cell-testability {
        flex: 0 0 88px;
        text-align: center;
    }
    .cell-conviction-level {
        flex: 0 0 124px;
        text-align: center;
    }
    .cell-status {
        flex: 0 0 104px;
        text-align: center;
    }
    .cell-empty {
        text-align: center;
        flex: 1 100%;
        color: $text-color-light;
    }
    .thead {
        .table-cell {
            font-weight: $font-weight-bold;
            font-size: 10px;
            line-height: 16px;
            align-items: center;
            letter-spacing: 0.06em;
            color: $priorization-header;
            padding: 8px 6px;
            background-color: $box-funding-footer-bg;
            border-bottom: 1px solid $box-border-color;
        }
    }

    .body {
        position: relative;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid $box-border-color;
        overflow: hidden;
        background-color: $box-funding-footer-bg;
        .table-cell {
            font-size: 14px;
            line-height: 16px;
            color: $text-color-dark;
            padding: 8px 6px;
            .form-group {
                margin: 0;
            }
            .form-group-checkbox {
                .custom-control {
                    padding: 0;
                    width: 24px;
                    margin: 0 auto;
                    .custom-control-label {
                        &:before,
                        &:after {
                            left: -8px;
                        }
                    }
                }
            }
            .form-group-select {
                .custom-select {
                    width: 72px;
                }
            }
            &.cell-collapse {
                text-align: center;
                .collpase-link {
                    .arrow-right {
                        display: block;
                        transition: all 0.3s ease;
                    }
                }
            }
            &.cell-empty {
                color: $text-color-light;
            }
        }
        &.without-background {
            background-color: $white;
        }
        .locked {
            position: absolute;
            width: 100%;
            height: 49px;
        }
        &:not(.collapse) {
            .cell-collapse {
                .collpase-link {
                    .arrow-right {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
    .collpased-row {
        position: relative;
        background-color: $box-funding-footer-border;
        max-height: 500px;
        transition: all 0.3s ease;
        .table-cell {
            padding: 12px 16px;
            flex: 1 100%;
            .test-description {
                margin: 0;
            }
            .test-progress-content {
                margin-top: 12px;
                display: flex;
                align-items: center;
                width: 100%;
                .test-progress-content-metric {
                    display: flex;
                    width: calc(100% - 314px - 61px);
                    padding-left: 16px;
                    padding-right: 32px;
                    .test-progress-content-metric-wrapper {
                        width: 100%;
                        .kpi-metric-content {
                            .kpi-metric-content-title {
                                display: inline-block;
                                margin-left: 8px;
                                > span {
                                    margin-left: 8px;
                                }
                                &:first-child {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
                .test-progress-content-due-date {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .due-date {
                        > span {
                            margin-left: 8px;
                        }
                    }
                    .btn {
                        min-width: 70px;
                        margin-left: 16px;
                    }
                }
            }
        }
        &.collapse {
            padding: 0;
            max-height: 0;
            margin: 0;
            overflow: hidden;
            border-width: 0px;
        }
    }
}

.challenge-finish-form {
    padding-top: 24px;
    padding-bottom: 40px;
    .title {
        color: $text-color-dark;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 24px;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            display: block;
            margin: 0 24px;
        }
    }
}

.my-challenges-list{
    display: block
}
.my-challenge-list-item{
    padding: 16px 24px 16px 17px;
    border-bottom: 1px solid $box-border-color;
    .my-challenge-content{
        .challenge-title{
            font-weight: $font-weight-bold;
            font-size: 14px;
            line-height: 16px;
            display: block;
            color: $text-color-dark;
            margin-bottom: 8px;
        }
        .challenge-description{
            font-weight: $font-weight-normal;
            font-size: 14px;
            line-height: 16px;
            display: block;
            color: $text-color-dark;
        }
    }
    .my-challenge-status{
        .badge-challenge{
            margin-right: 29px;
        }
        .btn{
            min-width: 41px;
        }
    }
    &:last-child{
        border-bottom: none;
    }
  
}
.my-challenge-list-item-empty{
    padding: 0px 16px;
    line-height: 73px;
    font-size: 14px;
}

#modal-add-challenge{
    .challenge-details{
        height: auto;        
        &.collapse{            
            height: 0;
            overflow: hidden;
        }
    }
}