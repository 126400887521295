.form-control.react-select {
    padding-right: 0;
    // position: relative;
    min-height: 3rem;
    height: auto;
    display: flex;
    .icon-saved {
        float: left;
        margin-top: 3px;
        margin-right: 8px;
    }
    .rs-value-container {
        align-items: center;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        padding: 0;
        -webkit-overflow-scrolling: touch;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
    }
    .rs-placeholder {
        $font-size: $input-font-size;
        color: $gray-400;
    }
    .rs-indicator-container {
        display: flex;
        .rs-indicator-separator {
        }
        .rs-indicator {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.15s ease-in-out;
            width: 36px;
            height: 100%;
            .svg-icon {
                transition: all 0.15s ease-in-out;
                svg {
                    fill: $gray-200;
                    transition: all 0.15s ease-in-out;
                    width: 24px;
                    height: 24px;
                }
                &.caret {
                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
        .dropdown-indicator {
            &:before {
                content: "";
                position: absolute;
                height: 60%;
                background-color: $gray-200;
                width: 1px;
                left: 0;
                top: 20%;
            }
        }
        .clear-indicator {
            .svg-icon {
                svg {
                    width: 20px;
                }
            }
            &:hover {
                cursor: pointer;
                .svg-icon {
                    svg {
                        fill: $primary;
                    }
                }
            }
        }
    }
    &.focus {
        color: #000;
        background-color: #fff;
        border-color: $primary;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba($primary, 0.25);
        .dropdown-indicator {
            .svg-icon {
                transform: rotate(-180deg);
                svg {
                    fill: $primary;
                }
            }
            &:before {
                background-color: $primary;
            }
        }
    }
}

.rs-menu {
    position: absolute;
    z-index: 999;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0 !important;
    .dropdown-item {
        border-top: 1px solid $gray-200;
        padding: 0.5rem 1rem;
        .icon-saved {
            line-height: 22px;
            float: left;
            margin-right: 8px;
        }
        &:first-child {
            border-top: none;
        }
        pointer-events: all;
        &.selected {
            font-weight: $font-weight-bold;
        }
        &.active {
            color: $white;
        }
    }
}

.rs-container {
    &.is-invalid {
        .form-control {
            background-image: none;
            border-color: $danger;
            .rs-indicator {
                &:not(.clear-indicator) {
                    .svg-icon {
                        svg {
                            fill: $danger;
                        }
                    }
                    &:before {
                        background-color: $danger;
                    }
                }
            }
            &.focus {
                box-shadow: 0 0 0 0.2rem rgba($danger, 0.25);
            }
        }
    }
    &.is-valid {
        .form-control {
            background-image: none;
            border-color: $success;
            .rs-indicator {
                &:not(.clear-indicator) {
                    .svg-icon {
                        svg {
                            fill: $success;
                        }
                    }
                    &:before {
                        background-color: $success;
                    }
                }
            }
            &.focus {
                box-shadow: 0 0 0 0.2rem rgba($success, 0.25);
            }
        }
    }
}
