.advice-list {
    max-height: 41px * 3;
    overflow-x: hidden;
    overflow-y: auto;
}

.advice-item {
    position: relative;
    border-bottom: 1px solid $box-funding-footer-border;
    padding: 12px;
    > a {
        color: $text-color-dark;
        font-size: 14px;
        line-height: 16px;
    }
}

.advice-item-empty {
    position: relative;
    border-bottom: 1px solid $box-funding-footer-border;
    padding-left: 56px;
    padding-right: 26px;    
    span {
        line-height: 64px;
        text-align: center;
        display: block;
        font-size: 14px;
    }
}
