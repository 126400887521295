.box-startup {
  display: flex;
  flex-direction: column;
  background: $white;
  // box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  background-color: $white;
  border: 1px solid $box-border-color;
  border-radius: 8px;
  // overflow: hidden;
  height: 100%;
  // margin-bottom: 16px;
  .box-startup-header {
    padding: 16px;
    background-color: $box-header-background;
    .title {
      line-height: 24px;
      display: block;
      font-size: 18px;
      font-weight: $font-weight-bold;
      color: $text-color-dark;
    }
    .title-description {
      font-style: italic;
      font-weight: $font-weight-bold;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .box-startup-body {
    padding: 16px;
    overflow: hidden;
    &.padding-12 {
      padding: 12px;
    }
    &.box-body-100 {
      height: calc(100% - 56px);
    }
    &.overflow-visible {
      overflow: visible;
    }
  }
  .box-startup-footer {
    margin-top: auto;
  }
  &.h-auto {
    height: auto;
  }
}

.box-fundable-admin {
  font-size: 18px;
  font-weight: $font-weight-medium;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  span:first-child {
    margin-bottom: 15px;
  }
  &.not_fundable {
    background-color: $admin-funding-not-fundable;
  }
  &.work_to_do {
    background-color: $admin-funding-work-to-do;
  }
  &.looking_good {
    background-color: $admin-funding-looking-good;
  }
  &.go_get_them {
    background-color: $admin-funding-go-get-them;
  }
}

.comparision-needle-item {
  display: flex;
  line-height: 24px;
  .comparision-text {
    font-size: 12px;
    color: $text-color-dark;
  }
}
.funding-stage-footer {
  padding: 12px;
  display: flex;
  flex: 1;
  background: $box-funding-footer-bg;
  border: 1px solid $box-funding-footer-border;
  .funding-stage-footer-column {
    display: flex;
    flex-direction: column;
  }
  .funding-stage-footer-title {
    span {
      display: block;
      font-weight: $font-weight-bold;
      font-size: 14px;
      color: $text-color-dark;
      white-space: nowrap;
      letter-spacing: -0.45px;
    }
  }
  .funding-stage-footer-stages {
    display: flex;
    padding-right: 8px;
    border-right: 1px solid $box-funding-footer-border-inner;
    .custom-checkbox {
      margin-left: 8px;
      .custom-control-label {
        white-space: nowrap;
        word-spacing: -1.3px;
        letter-spacing: -0.45px;
      }
    }
  }
  .funding-stage-footer-comparison {
    display: flex;
    .comparision-needle-item {
      margin-left: 8px;
    }
  }
  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    .funding-stage-footer-column-3 {
      padding-left: 106px;
      margin-top: 15px;
      flex: 0 0 100%; /* Let it fill the entire space horizontally */
    }
    .funding-stage-footer-stages {
      border: none;
    }
    .funding-stage-footer-comparison {
      .comparision-needle-item {
        &:first-child {
          margin-left: 0px;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .funding-stage-footer-column-2 {
      padding-left: 0;
      margin-top: 15px;
      flex: 0 0 100%; /* Let it fill the entire space horizontally */
    }
    .funding-stage-footer-column-3 {
      padding-left: 0;
      margin-top: 15px;
      flex: 0 0 100%; /* Let it fill the entire space horizontally */
    }
    .funding-stage-footer-stages {
      border: none;
      .custom-checkbox {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

#box-stuck-banner {
  .box-startup-body {
    padding: 48px 32px 48px 132px;
    position: relative;
    .tel-huge-bg {
      position: absolute;
      left: 16px;
      top: 50%;
      margin-top: -(86px / 2);
    }
    p {
      margin: 0;
      font-size: 22px;
      line-height: 24px;
      color: $text-color-dark;
      a {
        font-weight: $font-weight-bold;
      }
    }
  }
}

// Box Fundability
#box-fundability {
  .box-startup-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-content: center;
    justify-content: center;
  }
  #main-chart-container {
    padding: 16px;
    // @include media-breakpoint-up(lg) {
    //     padding-top: 28px;
    //     max-width: 390px;
    //     margin: 0 auto;
    // }
    // @include media-breakpoint-up(xl) {
    //     max-width: 360px;
    //     margin: 0 auto;
    // }
  }
}
// Box Fundability main Element
#box-fundability-main-element {
  #main-chart-container {
    @include media-breakpoint-up(lg) {
      max-width: 340px;
      margin: 0 auto;
    }
  }
}
// Box Fundability Sub Element
#box-fundability-sub-element {
  #main-chart-container {
    @include media-breakpoint-up(lg) {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}
// Box My Challenges
#box-my-challenges {
  .box-startup-header {
    .btn {
      min-width: 45px;
      margin-left: 17px;
    }
  }
}

// Box Challenge Suggestions
#box-suggestions-challenge {
  .challenge-table-wrapper {
    min-height: 156px;
    max-height: 156px;
    overflow-y: auto;
  }
}

#box-files-sub-element {
  @include media-breakpoint-up(lg) {
    height: 100%;
    .box-startup-body {
      height: calc(100% - 56px);
      .file-list {
        max-height: 100%;
        height: 100%;
        overflow-y: auto;
      }
    }
  }
}

#box-advices-sub-element {
  @include media-breakpoint-up(lg) {
    height: 100%;
    .box-startup-body {
      height: calc(100% - 56px);
      .advice-list {
        max-height: 100%;
        height: 100%;
        overflow-y: auto;
      }
    }
  }
}

.box-completion {
  .box-completion-advance {
    padding: 12px;
    background: $box-funding-footer-bg;
    border-bottom: 1px solid $box-border-color;
  }
  .box-completion-content {
    padding: 16px;
    p {
      color: $text-color-dark;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: $font-weight-normal;
    }
    img {
      margin-top: 17px;
    }
  }
  .box-completion-content-big {
    padding: 8px;

    p {
      color: $text-color-dark;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: $font-weight-normal;
    }
    img {
      margin-top: 17px;
    }
  }
  .box-completion-footer {
    padding: 16px;
    background: $box-funding-footer-bg;
    border-top: 1px solid $box-border-color;
    display: flex;
    justify-content: center;
  }
}
.box-element-scoring {
  .box-startup-body {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.box-priorization {
  .priorization-help {
    padding: 8px 16px;
    p {
      margin: 0;
      font-size: 12px;
      line-height: 16px;
      font-style: italic;
      color: $text-color-dark;
      white-space: pre-wrap;
    }
  }
}

#box-files-main-element {
  @include media-breakpoint-up(lg) {
    .box-startup-body {
      height: calc(100% - 56px);
      .file-list {
        max-height: 100%;
        height: 100%;
        min-height: auto;
      }
    }
  }
}

#box-fundability-founders {
  @include media-breakpoint-up(lg) {
    #main-chart-container {
      margin-top: 40px;
    }
  }
}
