.help {
    text-align: left;
    h3{
        font-size: 50px;
        font-weight: $font-weight-bold;
        color: $gray-500;
        @include media-breakpoint-down(md) {
            font-size: 24px;
        }

    }
    .help-body {
        padding: 22px 0;
        color: $gray-300;
        font-size: 14px;
    }
}
