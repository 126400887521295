.sidebar-main-dash {
    position: fixed;
    top: 0px;
    left: 0;
    background-color: $white;
    width: 200px;
    height: 100vh;
    transition: all 0.3s ease;
    border-right: 1px solid $navbar-brand-border;
    z-index: 1024;
    padding-bottom: 49px;
    .navbar-brand{
        padding: 20px 0;
        text-align: center;
        display: block;
        margin: 0;
    }
    .btn-toggle-sidebar {
        position: absolute;
        top: 28px;
        right: -14px;
        width: 28px;
        height: 28px;
        border-radius: 28px;
        border: 1px solid $primary;
        background-color: $white;
        color: $primary;
        padding: 0;
        display: inline-block;
        text-align: center;
        .icon-link {
            position: relative;
            transition: all 0.3s ease;
            display: inline-block;
            span {
                display: inline-block;
            }
            svg {
                width: 20px;
            }
        }
        &:hover {
            cursor: pointer;
            background-color: $primary;
            color: $white;
        }
    }
    .sidebar-main-dash-wrapper {
        width: 100%;
        height: calc( 100% - 72px);
        overflow-x: hidden;
        overflow-y: auto;
        .sidebar-main-dash-container {
            .gauge-item {
                padding: 0 20px;
                margin-top: 15px;
                h4 {
                    text-align: center;
                    font-weight: $font-weight-medium;
                }
                .gauge-item-chart {
                    max-width: 80px;
                    margin: 0 auto;
                    .donutText {
                        font-size: 8px;
                    }
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .sidebar-logout {
        padding: 4px 8px;
        border-top: 1px solid $box-border-color;

        .nav-item {
            .nav-link {
                padding: 8px 6px 8px 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 16px;
                line-height: 24px;
                color: $sidebar-link-color;
                border-radius: 4px;
                .icon-link {
                    margin-right: 14px;
                    svg{
                        width: 24px;
                    }
                }
                .btn-caret {
                    margin-left: auto;
                    padding: 0 10px;
                    display: none;
                }
                &:hover {
                    color: $text-color-dark;
                    .icon-link {
                        color: $text-color-dark;
                        svg {
                            path {
                                fill: $text-color-dark;
                            }
                        }
                    }
                    .btn-caret {
                        display: inline-block;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        transform: translateX(calc(-100% + 24px));
        .sidebar-main-dash-wrapper {
            visibility: hidden;
            opacity: 0;
            overflow-y: hidden;
        }
        &.show-sidebar {
            transform: translateX(0);
            .sidebar-main-dash-wrapper {
                visibility: visible;
                opacity: 1;
                overflow-y: auto;
            }
            .btn-toggle-sidebar {
                .icon-link {
                    svg {
                        transform: rotate(180deg);
                        transform-origin: 49%;
                    }
                }
            }
        }
    }
}
// Reset
.sidebar-menu,
.sidebar-sub-menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-menu {
    margin: 8px;
    > li {
        margin-bottom: 16px;
        .nav-link {
            padding: 8px 6px 8px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 15px;
            line-height: 18px;
            color: $sidebar-link-color;
            border-radius: 4px;
            position: relative;
            > span{
                white-space: pre-line;
            }
            .icon-link {
                margin-right: 14px;
                svg{
                    width: 24px;
                }
            }
            .btn-caret {
                margin-left: auto;
                padding: 0 10px;
                display: none;
                position: absolute;
                right: 0;
            }
            &:hover {
                color: $text-color-dark;
                .icon-link {
                    color: $text-color-dark;
                    svg {
                        path {
                            fill: $text-color-dark;
                        }
                    }
                }
                .btn-caret {
                    display: inline-block;
                }
            }
        }
        .nav-violet {
            color: $violet;
            font-weight: $font-weight-medium;
            &:hover {
                color: lighten($violet, 10%);
            }
            &.active {
                color: $text-color-dark;
                background-color: $sidebar-link-bg-color;
                font-weight: $font-weight-bold;
                .icon-link {
                    color: $text-color-dark;
                    svg {
                        path {
                            fill: $text-color-dark;
                        }
                    }
                }
            }
            &#nav-link-go-premium{
                &.active{
                    background-color: $violet;
                    color: $white;
                }   
                
            }
        }
        .sidebar-sub-menu {
            padding-left: 38px;
            max-height: 0;
            transition: all 0.35s ease-in-out;
            overflow: hidden;
            > li {
                .nav-link {
                    padding: 8px 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 14px;
                    line-height: 24px;
                    color: $sidebar-link-color;
                    border-radius: 4px;
                    .icon-link {
                        margin-right: 14px;
                    }
                    &:hover {
                        color: $text-color-dark;
                    }
                    &.active {
                        color: $text-color-dark;
                        font-weight: $font-weight-bold;
                    }
                }
                .nav-link-disabled{
                    .icon{
                        margin-right: 8px;
                        svg{
                            vertical-align: text-top;
                        }
                    }
                    &:hover{
                        cursor: not-allowed;
                        color: $sidebar-link-color;
                    }
                }
            }
            &.not-icon{
                padding-left: 15px;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &.active, &.toggle {
            > .nav-link {
                color: $text-color-dark;
                background-color: $sidebar-link-bg-color;
                font-weight: $font-weight-bold;
                .icon-link {
                    color: $text-color-dark;
                    svg {
                        path {
                            fill: $text-color-dark;
                        }
                    }
                }
            }
            .sidebar-sub-menu {
                max-height: 500px;
            }
        }

        &.divider {
            padding: 0 8px;
            margin: 12px 0;
            height: 1px;
            background-color: $box-border-color;
        }
    }
}
