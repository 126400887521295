.breadcrumb {
    > li {
      display: inline-block;
      > a{
          color: $gray-300;
          &:hover{
              color: $primary;
          }
          
      }
      + li:before {
        color: $gray-300;
      }
    }
  
    > .active {
        font-weight: $font-weight-medium;
    }
  }