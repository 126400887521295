// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Changing the fonts
$fonts-source: "../../fonts/";
$font-family-base: "Source Sans Pro", sans-serif;
$font-family-lato: "Lato", sans-serif;
$font-family-commissioner: "Commissioner", sans-serif;
// Enabling shadows and gradients
$enable-shadows: true;
// stylelint-disable
$white: #fff !default;
// Grays
$gray-100: #e5e5e5 !default;
$gray-110: #efeff4;
$gray-200: #ddd;
$gray-300: #8e8e93 !default;
$gray-400: #636466 !default;
$gray-500: #3b3b3b !default;
$gray-600: #221f20 !default;
$grays: (
  "100": $gray-100,
  "110": $gray-110,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
);
// Default
$black: #000 !default;
$dark: $black;
// Changing the theme colors
$primary: #282179 !default;
$secondary: #90217b !default;
// Boostrap colors
$info: #546cb9;
$success: darken(#3ddc97, 20%);
$warning: #ffc107;
$danger: #ff495c;
// Brand
$violet: #433d8f;
$light-violet: #d9d8e9;
$green: #3ddc97;
$startup-light: #f1f5f8;
// Admin colors
$admin-funding-empty: $white;
$admin-funding-not-fundable: #dddddd;
$admin-funding-work-to-do: #ffb1a6;
$admin-funding-looking-good: #ffec9a;
$admin-funding-go-get-them: #b0ec9a;
// Breadcrum colors
$breadcrumb-bg: $white !default;
$breadcrumb-active-color: $primary !default;
// Status Colors
$good: #42936d;
$fair: #eaa13b;
$poor: #cb3a32;
$drifting: #a0aec0;
$bad: #1e0601;
$in-progress: #6c727f;
$high: #2e277e;
$medium: rgba(54, 47, 132, 0.7);
$low: rgba(47, 41, 127, 0.4);
// Chart Colors
$arc-grey: #edf2f7;
$arc-label-color: #a0aec0;
$needle: $violet;
$dark-blue: #1c0d3d;
$dark-violet: #1c0d3d;
$progressBarBg: #f7fafc;
$progressBarBorder: #edf2f7;
// Box
$box-header-background: $progressBarBorder;
$box-border-color: #e2e8f0;
$box-funding-footer-bg: #f7fafc;
$box-funding-footer-border: #edf2f7;
$box-funding-footer-border-inner: #cbd5e0;
// Text color
$text-color-dark: #121024;
$text-color-light: #514e6b;
// Dashboard
$navbar-brand-border: $box-border-color;
$active-link: #1a202c;
$sidebar-link-color: #a0aec0;
$sidebar-link-bg-color: $progressBarBorder;
// Needle Color
// $needle-violet: #433d8f;
$needle-yellow: #f5c364;
// Priorization
$priorization-header: #2d3748;
// Main Element
$main-element-header-bg: #f9fcff;
// Multiple Steps
$mutilple-steps-nav: #ecebf3;
$multiple-steps-active: #413b8d;
// Questionnaire
$questionnaire-intructions-box-color: #2b257b;
// Numbers Colors
$numbers-colors: (
  "1": #CC3E32,
  "2": #D35534,
  "3": #DA6A36,
  "4": #E07F38,
  "5": #E6933A,
  "6": #DDA03F,
  "7": #B99D4A,
  "8": #969A54,
  "9": #71975F,
  "10": #42936D,
);
// Theme Colors
$theme-colors: (
  "green": $green,
  "startup-light": $startup-light,
  "admin-funding-empty": $admin-funding-empty,
  "admin-funding-not-fundable": $admin-funding-not-fundable,
  "admin-funding-work-to-do": $admin-funding-work-to-do,
  "admin-funding-looking-good": $admin-funding-looking-good,
  "admin-funding-go-get-them": $admin-funding-go-get-them,
  "arc-grey": $arc-grey,
  "arc-label-color": $arc-label-color,
  "needle": $needle,
  "dark-blue": $dark-blue,
  // Status
  "perfect": $good,
  "good": $good,
  "fair": $fair,
  "poor": $poor,
  "drifting": $drifting,
  "bad": $bad,
  "in-progress": $in-progress,
  "high": $high,
  "medium": $medium,
  "low": $low,
  // End Status
  "dark-violet": $dark-violet,
  "violet": $violet,
  "light-violet": $light-violet,
  "text-color-dark": $text-color-dark,
  "text-color-light": $text-color-light,
);
// Changing the body background and text
$body-bg: #e5e5e5;
$body-color: $text-color-light;
// Heading Sizes
$h1-font-size: 65px;
$h2-font-size: 32px;
$h3-font-size: 26px;
$h4-font-size: 22px;
// Font Weight Default Font
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-black: 900 !default;
$font-weights: (
  "light": $font-weight-light,
  "normal": $font-weight-normal,
  "medium": $font-weight-medium,
  "bold": $font-weight-bold,
  "black": $font-weight-black,
);
// Lato
$font-weight-lato-black: 900 !default;
$font-weights-lato: (
  "bold": $font-weight-lato-black,
);
// Comissioner
$font-weight-commissioner-normal: 400 !default;
$font-weight-commissioner-bold: 700 !default;
$font-weight-commissioner-extra-bold: 800 !default;
$font-weights-commissioner: (
  "normal": $font-weight-commissioner-normal,
  "bold": $font-weight-commissioner-bold,
  "extra-bold": $font-weight-commissioner-extra-bold,
);

// Navbar
$navbar-nav-link-padding-x: 10px;
$navbar-dark-color: $gray-300;
$navbar-dark-hover-color: $primary;

// Buttons
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-padding-y: 0px;
$btn-padding-x: 24px;
$btn-line-height: 38px;
// $btn-border-radius: 0;
$btn-font-size: 16px;
$btn-font-weight: $font-weight-bold;
$btn-padding-y-sm: 0;
// $btn-disabled-opacity: 0.8;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1440px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
);
$grid-gutter-width: 16px;
// Responsive
$font-size-sm: 10px;
// Forms
$input-font-size: 16px;
$input-border-radius: 4px;
$input-height: 2.5rem;
$input-placeholder-color: $gray-400;
$input-color: $dark;
$input-border-color: $box-border-color;
$input-padding-x: 0.5rem;
$input-disabled-bg: $gray-110;
$input-focus-border-color: $violet;
// Sm
$input-padding-y-sm: 0px;
$input-padding-x-sm: 8px;
$custom-select-padding-y-sm: 0px;
$custom-select-padding-x-sm: 8px;
// ReactToastify
$rt-color-default: #fff !default;
$rt-color-dark: #121212 !default;
$rt-color-info: $info;
$rt-color-success: $success;
$rt-color-warning: $warning;
$rt-color-error: $danger;
// Dropdown
$dropdown-padding-y:0px;
$dropdown-font-size: 14px;
$dropdown-link-hover-bg: $box-header-background;
$dropdown-link-color: $text-color-dark;

// Cards
$card-spacer-y:  16px;
$card-spacer-x:  16px;
$card-border-width: 1px;
$card-border-color: $box-border-color;
$card-border-radius: 8px;
$card-cap-bg:  $box-header-background;
        
