.card {
  .card-header {
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $text-color-dark;
    line-height: 24px;
  }
}

.collapse-card {
  padding: 16px;
}

