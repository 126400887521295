.best-practice-list {
  overflow-x: hidden;
  overflow-y: auto;
  
  @include media-breakpoint-down(md) {
      max-height: 63px * 5;
      height: 63px * 5;
  }
  @include media-breakpoint-up(lg) {
      max-height: 100%;
      height: 100%;
  }
  @include media-breakpoint-up(xl) {
      max-height: 100%;
      height: 100%;
  }
}
.best-practice-item {
  border-bottom: 1px solid $box-funding-footer-border;
  padding-right: 38px;
  padding-left: 15px;
  .file-content {
    position: relative;
    .icon-file {
      position: absolute;
      left: 0px;
      display: block;
      top: 50%;
      margin-top: -(32px / 2);
    }
    .file-item-content {
      padding-left: 38px;
      padding-right: 26px;
      padding-top: 16px;
      padding-bottom: 16px;
      .file-name {
        display: block;
        color: $text-color-dark;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 4px;
      }
      .file-date-and-size {
        display: block;
        color: $text-color-light;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
  .file-download-wrapper {
    position: relative;
    .file-download{
      &:hover{
        cursor: pointer;
      }
    }
    .rs-loader-wrapper {
      position: absolute;
      left: -3px;
      top: 3px;
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      cursor: pointer;
    }
    svg {
      width: 16px;
      height: 16px;
    }
    &.is-fetch {
      .icon-download {
        opacity: 0;
      }
      .rs-loader-wrapper {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .file-created {
    display: flex;
    align-items: center;
    height: 16px;
    font-size: 12px;
    .calendar-icon {
      margin-right: 8px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-right: 15px;
  }
}

.best-practice-item-empty{
  position: relative;
  border-bottom: 1px solid $box-funding-footer-border;
  span {
      line-height: 64px;
      text-align: center;
      display: block;
      font-size: 14px;
  }
}