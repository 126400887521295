.table-layout {
    
    .table-row {
       display: flex;
       flex-direction: row;
    }
    .table-cell {
        flex-direction: column;
        flex: 1 30%;    
    }
    
}

.table-search {
    margin-bottom: 26px;
    @include media-breakpoint-down(md) {
        .btn {
            margin: 15px 0 0px 0;
            width: 100%;
        }
    }
}

.table-startup-wrapper {
    table {
        table-layout: auto;
        thead {
            th {
                border-top-color: darken($primary, 10%);
                background-color: $primary;
                color: $white;
                border-bottom-color: darken($primary, 10%);
                outline: none;
                &.sortable {
                    min-width: 100px;
                }
                &.sort {
                    background-color: $secondary;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        tbody {
            tr {
                td {
                    border-top-color: $gray-200;
                    .actions-table-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        .action-table + .action-table {
                            margin-left: 10px;
                        }
                    }
                }
                &:first-child {
                    td {
                        border-top: none;
                    }
                }
            }
        }
    }
}
.react-bootstrap-table-pagination {
    .dropdown {
        display: inline-block !important;
        .btn {
            min-width: 50px;
        }
    }
    .react-bootstrap-table-pagination-total {
        margin-left: 15px;
    }
    .react-bootstrap-table-pagination-list {
        .pagination {
            float: right;
        }
    }
}

.table-without-results-label {
    font-size: 20px;
    font-weight: $font-weight-medium;
}

.table-totals-wrapper {
    padding: 5px;
    .table-totals {
        margin-bottom: 0;
    }
    .table-totals:last-child {
        margin-bottom: 15px;
    }
}
.table-totals {
    th {
        text-align: center;
        border-left: 1px solid $primary;
        border-right: 1px solid $primary;
    }
    td {
        text-align: center;
    }
    th.thead-question {
        width: 200px;
    }
    th.thead-value {
        width: 100px;
    }
    tr {
        &:not(.totals) {
            td {
                border: 1px solid $gray-200;
            }
        }
    }
}
.table-matrix-funding-calculation-responsive {
    height: calc(100vh - 100px);
}
.table-matrix-funding-calculation {
    .matrix-axis-y,
    .matrix-axis-x {
        vertical-align: middle;
        text-align: center;
        color: $white;
        font-weight: $font-weight-medium;
    }
    .matrix-axis-y {
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: $success;
    }
    .matrix-axis-x {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $primary;
    }
    .matrix-element {
        padding: 0;
        border: 1px solid;
        .matrix-element-container {
            padding-right: 30px;
            position: relative;
            input {
                min-width: 60px;
                padding: 0 0 0 15px;
                background-color: transparent;
                border: none;
                color: $dark;
            }
            .dropdown {
                position: absolute;
                right: 0;
                width: 30px;
                height: 100%;
                top: 0;
                .btn {
                    min-width: 30px;
                    width: 30px;
                    padding: 0;
                    height: 48px;
                    border-radius: 0;
                    line-height: 48px;
                    span {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 2px;
                    }
                    &:after {
                        content: none;
                    }
                }
            }
        }

        &.not_fundable {
            background-color: $admin-funding-not-fundable;
        }
        &.work_to_do {
            background-color: $admin-funding-work-to-do;
        }
        &.looking_good {
            background-color: $admin-funding-looking-good;
        }
        &.go_get_them {
            background-color: $admin-funding-go-get-them;
        }
    }
}

.table-priorization-edit {
    th.thead-order {
        width: 100px;
    }
    td.input-td {
        padding: 0;
        input {
            min-width: 75px;
            text-align: center;
        }
    }
}

.table-weights-edit {
    th.thead-title {
        width: 125px;
    }
    td.input-td {
        padding: 0;
        input {
            min-width: 75px;
            text-align: center;
        }
    }
}
.level-criticaly-table-container {
    border-radius: 4px;
    border: 1px solid $box-border-color;
    overflow: hidden;
    .level-criticaly-table {
        background-color: $white;
        border: none;
        width: 100%;
        thead {
            tr {
                th {
                    width: 33.3%;
                    font-size: 12px;
                    line-height: 16px;
                    border-right: 1px solid $box-border-color;
                    padding: 8px 0px;
                    white-space: nowrap;
                    font-weight: $font-weight-normal;
                    text-align: center;
                    &:last-child {
                        border-right: none;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    text-align: center;
                    font-weight: $font-weight-bold;
                    font-size: 12px;
                    line-height: 16px;
                    color: $white;
                    border-right: 1px solid $box-border-color;
                    padding: 8px 0;
                    text-transform: uppercase;
                    &:last-child {
                        border-right: none;
                    }
                    &.empty{
                        background-color: $white;
                        color: transparent;
                    }
                    &.low{
                        background-color: $low;
                    }
                    &.medium{
                        background-color: $medium;
                    }
                    &.high{
                        background-color: $high;

                    }

                }
            }
        }
    }
}
