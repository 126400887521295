.sun-editor {
    .se-wrapper {
        .se-placeholder {
            font-family: $font-family-base;
            font-size: 1rem;
            color: $input-placeholder-color;
            font-weight: $font-weight-normal;
        }
    }
    .se-dialog {
        .se-dialog-inner {
            .se-dialog-form{
                .se-dialog-form-files{
                    > input{
                        overflow: hidden;
                    }
                }
            }
            .se-dialog-content{
                .se-btn-primary{
                    @extend .btn;
                    @extend .btn-sm;
                    @extend .btn-violet;
                }
            }

        }
    }    
}

