.comparision-icon {
    display: inline-block;
    margin-left: 4px;
    span {
        display: inline-block;
        svg {
            fill: $box-funding-footer-border-inner;
        }
    }
    &.violet {
        span {
            svg {
                fill: $needle;
            }
        }
    }
    &.yellow {
        span {
            svg {
                fill: $needle-yellow;
            }
        }
    }
}