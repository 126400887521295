.slick-list,
.slick-track {
    height: 100%;
}
.slick-slide {
    div {
        height: 100%;
        outline: none;
    }
}
.inspekt-carousel-images {
    height: 277px;
    padding-bottom: 27px;
    margin-bottom: 15px;
    .item-slider {
        height: 100%;
        padding: 5px;
        line-height: 100%;
        vertical-align: middle;
        text-align: center;
        position: relative;
        transition: all 0.3s ease;
        .btn{
            min-width: 30px;
            width: 30px;
            height: 30px;
            border-radius: 40px;
            position: absolute;
            top: 10px;
            opacity: 0;
            visibility: hidden;
            margin: 0;
            padding: 0;
            border: none;
            background-color: $gray-200;
            line-height: 30px;
            span{
                font-size: 24px;
                vertical-align: middle;
            }
            &.item-slider-fullscreen{
                left: 8px;
            }
            &.item-slider-delete{
                right:8px;
            }
            &:hover{
                background-color: $primary;
                color: $white;
            }
        }
        .item-slider-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background-color: $gray-100;
            overflow: hidden;
            img {
                max-width: 100%;
            }
        }
        .item-slider-description{
            position: absolute;
            background-color: $primary;
            color: $white;
            padding: 10px;
            bottom: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            margin:0;
        }
        &:hover {
            cursor: pointer;
            background-color: $primary;
            .btn{
                visibility:visible;
                opacity: 1;
            }
            .item-slider-description{
                opacity: 1;
            }
        }
        
    }
    @include media-breakpoint-down(md) {
        height: 377px;
    }
}

.slick-dots{
    bottom: 0 !important;
    > li{
        button{
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: $gray-200;
            margin: 5px auto 0 auto;
            transition: all 0.3s ease;
            &:before{
                content: none;
            }
            &:hover{
                // background-color: $;
                box-shadow: 0 0 0 2px $gray-300;
            }
        }
        &.slick-active{
            button{
                background-color: $primary;
                &:hover{
                    box-shadow: 0 0 0 2px lighten($primary,10%);
                }
            }
        }
    }
}
.slick-arrow{
    width: 40px;
    height: 40px;
    background-color: $primary;
    border-radius: 40px;
    text-align: center;
    transition: all 0.3s ease;
    z-index: 99;
    transform: translate(0, calc(-50% - 13px));
    &:before{
        content: none;
    }
    span{
        color: $white;
        font-size: 40px;
    }
    &:hover{
        background-color: lighten($primary,10%);
        box-shadow: 0 0 0 2px lighten($primary,10%);
    }
}